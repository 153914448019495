<template>
	<div class="blogs">

		<Head :title="Head.Title" :baliseTitle="Head.baliseTitle" :titleWeight="Head.TitleWeight" :subTitle="Head.SubTitle"
			:subTitle2="Head.SubTitle2" :bgUrl="Head.bgUrl" :bgUrlLazy="Head.bgUrlLazy" :offset="Head.offset"
			:height="Head.height" :overlayOpacity="Head.overlayOpacity" :overlayColor="Head.overlayColor"
			:imgBlur="Head.imgBlur" :transitionRotate="Head.transitionRotate" :titleColor="Head.TitleColor" />

		<v-container>
			<v-card class="glassmorphism pa-4 pt-0 mx-auto" max-width="1280" color="transparent">
				<ButtonTrigger @click.native="dialog.add = !dialog.add" title="Blogs" content="Ajouter un Blog"
					whiteText />
				<v-col cols="12">
					<v-expansion-panels class="mx-auto">
						<v-expansion-panel v-for="item in blogs" :key="item.id">
							<v-expansion-panel-header>
								<v-row>
									<v-col cols="12" class="ml-0 pl-0">
										<v-btn v-if="$vuetify.breakpoint.smAndUp" class="neon" icon color="primary"
											:to="`blog/${item.id}`">
											<v-icon>mdi-eye</v-icon>
										</v-btn>
										<v-menu v-if="$vuetify.breakpoint.smAndUp" offset-y>
											<template v-slot:activator="{ on, attrs }">
												<v-btn class="neon" icon color="primary" v-bind="attrs" v-on="on">
													<v-icon>mdi-information-outline</v-icon>
												</v-btn>
											</template>
											<v-card class="pa-4">
												<v-row>
													<v-col cols="12">
														<span class="font-italic font-weight-light">Date de
															Création :
															{{ $functions.getDateFormat(item.creationDate) }}</span>
													</v-col>
													<v-col cols="12">
														<span class="font-italic font-weight-light">Identifiant
															du Blog : {{ item.id }}</span>
													</v-col>
												</v-row>
											</v-card>
										</v-menu>
										<span class="overline"
											:class="$vuetify.breakpoint.smAndUp ? 'pl-8' : 'pl-2'">{{ item.name }}
										</span>
									</v-col>
								</v-row>
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<v-row>
									<v-col cols="12" sm="5">
										<v-btn class="neon" block outlined color="primary" :to="`blog/${item.id}`">
											Accéder
											au blog</v-btn>
									</v-col>
									<v-col cols="12" sm="5">
										<v-btn class="neon" block outlined color="primary" :to="`blog/${item.id}/add`">
											Ajouter un article</v-btn>
									</v-col>
									<v-col cols="12" sm="2">
										<v-btn class="neon float-right" block outlined color="error"
											@click="dialog.delete = true, blogToDelete = item">
											<v-icon>mdi-delete</v-icon>
										</v-btn>
									</v-col>
								</v-row>
							</v-expansion-panel-content>
							<template v-slot:actions>
								<v-icon color="error">
									mdi-alert-circle
								</v-icon>
							</template>
						</v-expansion-panel>
					</v-expansion-panels>
				</v-col>
			</v-card>
		</v-container>
		<v-dialog v-model="dialog.add" max-width="600">
			<v-card class="glassmorphism" color="transparent">
				<ButtonTrigger title="Ajouter un Blog" small whiteText :noButton="$vuetify.breakpoint.smAndUp" icon
					content="" @click.native="dialog.add = false" />
				<v-col cols="12">
					<v-card class="bt-primary">
						<v-card-text>
							<v-container>
								<v-row>
									<v-col cols="12">
										<v-form>
											<v-row>
												<v-col cols="12" sm="8" md="12">
													<v-text-field label="Nom du Blog"
														:rules="$fieldsRules.required('nom')" v-model="blogName">
													</v-text-field>
												</v-col>
												<v-col cols="12" sm="4" md="12">
													<v-btn :disabled="!blogName" :block="$vuetify.breakpoint.smAndDown"
														:color="$vuetify.theme.dark ? 'white' : 'primary'"
														class="neon evidence float-right" outlined
														@click="addBlog(blogName), dialog.add = false">
														Ajouter un blog
													</v-btn>
												</v-col>
											</v-row>
										</v-form>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>
					</v-card>
				</v-col>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialog.delete" max-width="600">
			<v-card class="glassmorphism" color="transparent">
				<ButtonTrigger title="Supprimer le Blog" small whiteText :noButton="$vuetify.breakpoint.smAndUp" icon
					content="" @click.native="dialog.delete = false" />
				<v-col cols="12">
					<v-card class="bt-error">
						<v-card-title class="headline">
							Voulez-vous supprimer le blog {{ blogToDelete.name }} ?
						</v-card-title>
						<v-card-text>
							<p>Après avoir cliqué sur "Confirmer la suppression", le blog ne sera plus disponible et
								tous ses articles seront supprimés définitivement.</p>
							<v-container>
								<v-row>
									<v-col cols="12">
										<v-form>
											<v-row>
												<v-col cols="12" md="12" lg="6" v-for="item in blogToDelete.articles"
													:key="item.id">
													<v-card class="zoom" hover outlined
														:to="`blog/${blogToDelete.id}/article/${item.id}`">
														<v-img v-if="item.img.main"
															gradient="to top left, rgba(100,115,201,.33), rgba(25,32,72,.7)"
															class="pa-4" height="150" :src="$functions.getImgLink(item.img.main, 200)">
															<h3 class="white--text">{{ item.title }}</h3>
															<h4
																class="absolute top left pl-4 pt-10 subtitle-1 white--text">
																{{ item.subTitle }}
															</h4>
															<v-btn small outlined color="white"
																class="neon absolute bottom right mb-4 mr-4"
																:to="`blog/${blogToDelete.id}/article/${item.id}`">
																Voir l'article
															</v-btn>
														</v-img>

														<v-card height="150" v-if="!item.img.main"
															gradient="to top left, rgba(100,115,201,.33), rgba(25,32,72,.7)"
															class="d-flex justify-center">
															<h3 class="absolute top left pl-4 pt-4">{{ item.title }}
															</h3>
															<h4 class="absolute top left pl-4 pt-10 subtitle-1">
																{{ item.subTitle }}
															</h4>
															<v-icon>mdi-newspaper</v-icon>
															<v-btn small outlined
																:color="$vuetify.theme.dark ? 'white' : 'black'"
																class="neon absolute bottom right mb-4 mr-4"
																:to="`blog/${blogToDelete.id}/article/${item.id}`">
																Voir l'article
															</v-btn>
														</v-card>
													</v-card>
												</v-col>
											</v-row>
										</v-form>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>
						<v-card-actions>
							<v-row>
								<v-col cols="12">
									<v-form>
										<v-row>
											<v-col cols="12">
												<v-spacer></v-spacer>
												<v-btn :block="$vuetify.breakpoint.xsOnly" outlined color="error"
													class="neon evidence mb-4" @click="removeBlog(blogToDelete.id)">
													Confirmer la suppression
												</v-btn>
												<v-btn :block="$vuetify.breakpoint.xsOnly" outlined color="success"
													class="neon evidence mb-4 float-right"
													@click="dialog.delete = false">
													Annuler
												</v-btn>
											</v-col>
										</v-row>
									</v-form>
								</v-col>
							</v-row>
						</v-card-actions>
					</v-card>
				</v-col>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import Head from '@/components/graphics/head'
	import ButtonTrigger from '@/components/graphics/buttonTrigger'

	export default {
		head: {
			title: {
				inner: "Blogs"
			}
		},
		name: 'Blogs',
		components: {
			Head,
			ButtonTrigger
		},
		data() {
			return {
				userInfo: this.$models.user,

				blogName: '',
				blogToDelete: {},

				blogs: [],

				dialog: {
					add: false,
					delete: false
				},

				Head: {
					Title: 'Blogs',
					baliseTitle: 'h2', // h1, h2, h3, h4, h5, h6
					TitleWeight: 'thin', // thin / light / regular / medium / bold / black
					TitleColor: 'white', // white / black / primary

					SubTitle: '',
					SubTitle2: "",

					bgUrl: "https://hostcrush.cloud/echange/VueDesignProps/Backgrounds/ktna-bg.jpg",
					bgUrlLazy: "https://hostcrush.cloud/echange/VueDesignProps/Backgrounds/ktna-bg-lazy.jpg",

					offset: "15vh",
					height: "65vh",

					overlayOpacity: "0.3",
					overlayColor: "black",
					imgBlur: "0px",

					transitionRotate: "180"

				},
			};
		},
		methods: {
			addBlog(blogName) {
				if (!blogName) return;

				this.$db.collection("companies").doc(this.userInfo.company).collection("blogs").add({
					name: blogName,
					creationDate: new Date()
				}).then(() => {
					this.$store.dispatch('setSnackbar', {
						text: "Le blog a bien été ajouté.",
						color: "success"
					});

					this.blogName = '';
				});
			},
			removeBlog(blogID) {
				this.$katana.removeAllDataFromDocument(this.$db.collection("companies").doc(this.userInfo.company).collection("blogs").doc(blogID), "Le blog a bien été supprimé.");
				this.dialog.delete = false;
			}
		},
		created() {
			this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).onSnapshot(doc => {
				this.userInfo = {
					...doc.data(),
					id: doc.id
				};

				this.$db.collection("companies").doc(this.userInfo.company).collection("blogs").onSnapshot(res => {
					this.blogs = [];

					res.forEach((document) => {

						this.$db.collection("companies").doc(this.userInfo.company).collection(
							"blogs").doc(document.id).collection("articles").get().then(
							resA => {
								var blogArticles = [];

								resA.forEach((article) => {
									blogArticles.push({
										...article.data(),
										id: article.id
									});
								});

								this.blogs.push({
									...document.data(),
									id: document.id,
									articles: blogArticles
								});
							});
					});
				});
			});
		}
	}

</script>
